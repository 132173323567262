import { isCorpTenant } from "@capone/common";
import { Tenant } from "redmond";

export const INPUT_LABEL = (
  adults: number,
  children: number[],
  rooms: number,
  showRoomCountsOnly?: boolean,
  showTotalTravelers?: boolean
) => {
  const adultsCount = adults === 1 ? `${adults} Adult` : `${adults} Adults`;
  const childrenCount =
    children.length === 1
      ? `${children.length} Child`
      : `${children.length} Children`;
  const travelersCount = `${children.length + adults} Traveler${
    children.length + adults > 1 ? "s" : ""
  }`;
  const roomCount = rooms === 1 ? `${rooms} Room` : `${rooms} Rooms`;
  if (showRoomCountsOnly) {
    return `<span class="rooms-count"> ${roomCount}</span>`;
  }
  if (showTotalTravelers)
    return `<span class="travelers-count">${travelersCount},</span><span class="rooms-count">${roomCount}</span>`;
  return `<span class="adults-count">${adultsCount}</span><span class="children-count">${childrenCount}</span><span class="rooms-count"> ${roomCount}</span>`;
};

export const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "",
};

export const MODAL_TITLES_WITH_ROOMS = (
  tenant: Tenant,
  maxRooms: number
) => ({
  modalTitle: isCorpTenant(tenant)
    ? "Edit travelers and rooms"
    : "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Under 18",
  roomsTitle: "Rooms",
  roomsSubtitle: `Max ${maxRooms}`,
});

export const PETS_TITLE = "Traveling with pets?"
export const PETS_SUBTITLE = "Fee may apply"
export const PETS_TOOLTIP =
  "If you’re traveling with a pet, you need to book a pet-friendly stay. Adding a pet will display only pet-friendly stays in your search.<br/> <br/>If you’d like to bring more than 1 pet, please discuss with your stay after booking. Service animals do not count as pets—you don’t need to add them to your guest count, and you can inform your stay provider after booking.";
export const PETS_MODAL_HEADER = "Pets";