export const FETCH_HOTEL_SHOP = "hotelShop/FETCH_HOTEL_SHOP";
export type FETCH_HOTEL_SHOP = typeof FETCH_HOTEL_SHOP;

export const SET_HOTEL_SHOP_RESULTS = "hotelShop/SET_HOTEL_SHOP_RESULTS";
export type SET_HOTEL_SHOP_RESULTS = typeof SET_HOTEL_SHOP_RESULTS;

export const SET_HOTEL_SHOP_CALL_STATE_FAILED =
  "hotelShop/SET_HOTEL_SHOP_CALL_STATE_FAILED";
export type SET_HOTEL_SHOP_CALL_STATE_FAILED =
  typeof SET_HOTEL_SHOP_CALL_STATE_FAILED;

export const SELECT_ROOM_TYPE = "hotelShop/SELECT_ROOM_TYPE";
export type SELECT_ROOM_TYPE = typeof SELECT_ROOM_TYPE;

export const ACKNOWLEDGE_HOTEL_SHOP_FAILURE =
  "hotelShop/ACKNOWLEDGE_HOTEL_SHOP_FAILURE";
export type ACKNOWLEDGE_HOTEL_SHOP_FAILURE =
  typeof ACKNOWLEDGE_HOTEL_SHOP_FAILURE;

export const SELECT_LODGING = "hotelShop/SELECT_LODGING";
export type SELECT_LODGING = typeof SELECT_LODGING;

export const RESET_LODGING = "hotelShop/RESET_LODGING";
export type RESET_LODGING = typeof RESET_LODGING;

export const SET_DATE_RANGE = "hotelShop/SET_DATE_RANGE";
export type SET_DATE_RANGE = typeof SET_DATE_RANGE;

export const SET_HOTEL_SHOP_PROGRESS = "hotelShop/SET_HOTEL_SHOP_PROGRESS";
export type SET_HOTEL_SHOP_PROGRESS = typeof SET_HOTEL_SHOP_PROGRESS;

export const SET_SIMILAR_HOTELS = "hotelShop/SET_SIMILAR_HOTELS";
export type SET_SIMILAR_HOTELS = typeof SET_SIMILAR_HOTELS;

export const SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE =
  "hotelShop/SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE";
export type SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE =
  typeof SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE;

export const FETCH_NEARBY_LODGINGS = "hotelShop/FETCH_NEARBY_LODGINGS";
export type FETCH_NEARBY_LODGINGS = typeof FETCH_NEARBY_LODGINGS;

export const SET_NEARBY_LODGINGS = "hotelShop/SET_NEARBY_LODGINGS";
export type SET_NEARBY_LODGINGS = typeof SET_NEARBY_LODGINGS;

export const SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE =
  "hotelShop/SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE";
export type SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE =
  typeof SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE;

export const FETCH_DISTANCE_FROM_LOCATION_CATEGORIES =
  "hotelShop/FETCH_DISTANCE_FROM_LOCATION_CATEGORIES";
export type FETCH_DISTANCE_FROM_LOCATION_CATEGORIES =
  typeof FETCH_DISTANCE_FROM_LOCATION_CATEGORIES;

export const SET_DISTANCE_FROM_LOCATION_CATEGORIES =
  "hotelShop/SET_DISTANCE_FROM_LOCATION_CATEGORIES";
export type SET_DISTANCE_FROM_LOCATION_CATEGORIES =
  typeof SET_DISTANCE_FROM_LOCATION_CATEGORIES;

export const SET_DISTANCE_FROM_LOCATION =
  "hotelShop/SET_DISTANCE_FROM_LOCATION";
export type SET_DISTANCE_FROM_LOCATION = typeof SET_DISTANCE_FROM_LOCATION;

export const SET_HOTEL_SHOP_ENTRY_POINT =
  "hotelShop/SET_HOTEL_SHOP_ENTRY_POINT";
export type SET_HOTEL_SHOP_ENTRY_POINT = typeof SET_HOTEL_SHOP_ENTRY_POINT;

export const SET_SELECTED_ROOM_ID = "hotelShop/SET_SELECTED_ROOM_ID";
export type SET_SELECTED_ROOM_ID = typeof SET_SELECTED_ROOM_ID;

export const SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES =
  "hotelShop/SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES";
export type SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES =
  typeof SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES;
