import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getHotelAvailabilitySearchLocationResult,
  getHotelQueryParams,
  getMapBound,
  getViewedHotelListProperties,
} from "../../../../reducer";
import { AvailabilityMapPricePin } from "./component";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../../../rewards/reducer";
import { getRoomsCount } from "../../../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    hotelQueryParams: getHotelQueryParams(state),
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    viewedHotelListProperties: getViewedHotelListProperties(state),
    searchLocation: getHotelAvailabilitySearchLocationResult(state),
    roomsCount: getRoomsCount(state),
    mapBounds: getMapBound(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedAvailabilityMapPricePinProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityMapPricePin = connector(
  AvailabilityMapPricePin
);
