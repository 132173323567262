import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { actions } from "../../actions";
import {
  getUntilDate,
  getFromDate,
  getNightCount,
} from "../../../search/reducer";
import { HotelShopCallState } from '../../reducer/state';
import {
  getHotelShopRoomInfoProducts,
  getHotelShopCallState,
  getHotelShopSelectedLodging,
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
} from "../../reducer";
import { DesktopRoomPicker } from "./component";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  const checkinDate = getFromDate(state);
  const checkoutDate = getUntilDate(state);
  const chosenRoomInfoIndex = getHotelShopChosenRoomInfoIndex(state);
  const chosenProductIndex = getHotelShopChosenProductIndex(state);
  const chosenRoomInfo = getHotelShopChosenRoomInfo(state);
  const chosenProduct = getHotelShopChosenProduct(state);
  const selectedLodging = getHotelShopSelectedLodging(state);
  const nightCount = getNightCount(state);
  const rewardsKey =
    getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined;
  const roomInfoProducts = getHotelShopRoomInfoProducts(state);
  const isHotelShopLoading =
    getHotelShopCallState(state) === HotelShopCallState.InProcess;

  return {
    checkinDate,
    checkoutDate,
    chosenRoomInfoIndex,
    chosenProductIndex,
    chosenRoomInfo,
    chosenProduct,
    selectedLodging,
    nightCount,
    rewardsKey,
    roomInfoProducts,
    isHotelShopLoading,
  };
};

const mapDispatchToProps = {
  // TODO: Implement navigation to summary
  // seeHotelSummary: seeHotelSummary,
  selectRoomType: actions.selectRoomType,
  fetchHotelShop: actions.fetchHotelShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopRoomPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopRoomPickerPicker = withRouter(
  connector(DesktopRoomPicker)
);
