import clsx from "clsx";
import { HotelShopRoomTypePicker, TrackingEventControlType } from "halifax";
import React, { useEffect, useMemo, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { SELECT_HOTEL_ROOM } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  HOTEL_COLOR_CALENDAR,
  HOTEL_COLOR_CALENDAR_VARIANTS,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { CalendarPickerButton } from "../../../search/components/HotelSearchControl/components/CalendarPickerButton";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { DesktopRoomPickerConnectorProps } from "./container";
import "./styles.scss";

export interface IDesktopRoomPickerProps
  extends DesktopRoomPickerConnectorProps,
    RouteComponentProps {
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
}

export const DesktopRoomPicker = (props: IDesktopRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchHotelShop,
    handleReadyToRedirect,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);

  const expState = useExperiments();

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    DESKTOP_RECENTLY_VIEWED_HOTELS,
    DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const colorCalendarExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_COLOR_CALENDAR,
    HOTEL_COLOR_CALENDAR_VARIANTS
  );

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchHotelShop(history, {
          forceCallHotelAvailability: true,
          fetchSimilarHotels: similarHotelsVariant !== CONTROL,
          includeLocationSearchTerm:
            isRecentlyViewedV1Experiment &&
            recentlyViewedDesktopP0Variant !== CONTROL &&
            shouldTrackLodgingLocation(history.location.search),
        });
      }
    }
  }, [checkinDate, checkoutDate]);

  return (
    <HotelShopRoomTypePicker
      {...props}
      datepicker={
        <CalendarPickerButton
          classes={["room-calendar-button"]}
          hideSeparator
          showCalenderPricingColors={colorCalendarExperimentVariant !== CONTROL}
          showCalendarPricing={
            colorCalendarExperimentVariant === HOTEL_COLOR_CALENDAR_WITH_PRICING
          }
        />
      }
      onClickContinue={() => {
        const room = props.chosenRoomInfo && props.chosenRoomInfo.roomInfo.name;
        trackEvent({
          eventName: SELECT_HOTEL_ROOM,
          properties: { room_type: room },
        });
        if (handleReadyToRedirect) {
          handleReadyToRedirect();
        }
      }}
      className={clsx("b2b", "desktop-room-picker")}
      hideAll={true}
    />
  );
};
