import { createSelector } from "reselect";
import {
  getImageUrlsArray,
  getModifiedLegacyPrices,
  getAddedLegacyPrices,
} from "halifax";
import {
  ITrackingProperties,
  SearchDistanceFromShopProperties,
  ViewedHotelDetailsProperties,
  HotelCfarQuote,
  HotelCancellationPolicyV2,
  HotelCfarQuoteAdditionalInfo,
  PassengerAncillaryPricing,
  AncillaryKindEnum,
  RoomInfoProductsWithTransformedIndexes,
  RoomProductType,
  RoomProduct,
  RoomProductWithTransformedIndexes,
  getShopRoomInfoProductsWithTransformedIndexes,
  Cap1HotelCfarOfferFactsProperties,
  getLodgingTrackingProperties,
  LodgingPriceFreezeOffer,
  CorpRoomProduct,
  RoomInfoProducts,
  CorpRoomInfoProducts,
  Place,
  Lodging,
} from "redmond";
import queryStringParser from "query-string";

import { IStoreState } from "../../../../reducers/types";
import { HotelShopCallState } from "../state";
import {
  getViewedHotelListProperties,
  getSearchedNightCount,
} from "../../../availability/reducer";
import {
  lodgingIdWithCfarQuotesSelector,
  getHotelCfarQuoteByIndexSelector,
  getHotelCancellationPolicyByIndexSelector,
  getHotelAdditionalInfoByIndexSelector,
  isHotelCfarEnabledSelector,
  selectedCfarIdSelector,
  hasSelectedRefundableRoomSelector,
  DO_NOT_APPLY_OPTION_KEY,
  isRefundableRoomsWithPartiallyRefundablePolicyEnabledSelector,
  isHotelCfarRrMultipleCancellationPoliciesEnabledSelector,
  isHotelCfarModelV1EnabledSelector,
  shouldDisableRefundableRoomsSelector,
  useMatchCfarQuoteWithRoomIdSelector,
  getHotelCfarQuoteByRoomIdSelector,
  getHotelCfarQuoteAdditionalInfoByRoomIdSelector,
  getHotelCfarQuoteCancellationByRoomIdSelector,
} from "../../../ancillary/reducer";
import { roomInfoCfarQuotesSelector } from "../../../ancillary/reducer/selectors";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import { hotelPriceFreezeOffersSelector } from "../../../freeze/reducer";

export const getHotelShopSelectedLodging = (state: IStoreState) =>
  state.hotelShop.selectedLodging;

export const getHotelShopDateRange = (state: IStoreState) =>
  state.hotelShop.dateRange;

export const getHotelShopSelectedLodgingId = createSelector(
  getHotelShopSelectedLodging,
  (selectedLodging) => {
    return selectedLodging?.lodging.id;
  }
);

export const getHotelShopImageUrlsArray = createSelector(
  [getHotelShopSelectedLodging],
  (availability) => {
    return getImageUrlsArray(availability?.lodging.media ?? []);
  }
);

export const getHotelShopShopRequestId = createSelector(
  [getHotelShopSelectedLodging],
  (availability) => {
    return availability?.price?.opaqueShopRequest;
  }
);

export const getHotelOpaqueCfarQuotesRequestSelector = (state: IStoreState) =>
  state.hotelShop.hotelOpaqueCfarQuotesRequest;

export const getHotelShopRoomInfoProducts = (state: IStoreState) =>
  state.hotelShop.roomInfoProducts;

export const getHotelShopTrackingPropertiesV2 = (state: IStoreState) =>
  state.hotelShop.hotelShopTrackingPropertiesV2;

export const getHotelShopChosenRoomInfoIndex = (state: IStoreState) =>
  state.hotelShop.chosenRoomInfoIndex;

export const getHotelShopChosenProductIndex = (state: IStoreState) =>
  state.hotelShop.chosenProductIndex;

export const getHotelShopChosenRateIdSelector = (state: IStoreState) =>
  state.hotelShop.chosenRateId;

export const getSelectedRoomIdSelector = (state: IStoreState) =>
  state.hotelShop.selectedRoomId;

export const getHotelShopCancellationSummary = (state: IStoreState) =>
  state.hotelShop.cancellationSummary;

export const getHotelShopChosenRoomInfo = createSelector(
  [getHotelShopRoomInfoProducts, getHotelShopChosenRoomInfoIndex],
  (
    roomInfoProducts,
    chosenRoomInfoIndex
  ): RoomInfoProducts | CorpRoomInfoProducts | null => {
    if (chosenRoomInfoIndex !== null) {
      return roomInfoProducts[chosenRoomInfoIndex] ?? null;
    }

    return null;
  }
);

export const getHotelShopChosenProduct = createSelector(
  [
    getHotelShopRoomInfoProducts,
    getHotelShopChosenRoomInfoIndex,
    getHotelShopChosenProductIndex,
  ],
  (
    roomInfoProducts,
    chosenRoomInfoIndex,
    chosenProductIndex
  ): RoomProduct | CorpRoomProduct | null => {
    if (chosenRoomInfoIndex !== null && chosenProductIndex !== null) {
      return (
        roomInfoProducts[chosenRoomInfoIndex]?.products[chosenProductIndex] ??
        null
      );
    }

    return null;
  }
);

export const getHotelShopChosenProductCorporateTravel = createSelector(
  [getHotelShopChosenProduct],
  (chosenProduct) => {
    return (chosenProduct as CorpRoomProduct)?.corporateTravel;
  }
);

export const getHotelShopCallState = (state: IStoreState) =>
  state.hotelShop.hotelShopCallState;

export const getHotelShopCallError = (state: IStoreState) =>
  state.hotelShop.hotelShopCallError;

export const getCheapestProduct = createSelector(
  getHotelShopRoomInfoProducts,
  (roomInfoProducts) => roomInfoProducts[0]?.products[0]
);

export const isMissingProductInfo = createSelector(
  getHotelShopCallState,
  getHotelShopRoomInfoProducts,
  (hotelShopCallState, roomInfoProducts) => {
    return (
      hotelShopCallState === HotelShopCallState.Success &&
      roomInfoProducts.length === 0
    );
  }
);

export const hasHotelShopFailed = createSelector(
  getHotelShopCallState,
  isMissingProductInfo,
  (hotelShopCallState, missingProductInfo) => {
    return (
      hotelShopCallState === HotelShopCallState.Failed || missingProductInfo
    );
  }
);

export const getViewedHotelDetailsTrackingProps = createSelector(
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfo,
  getHotelShopTrackingPropertiesV2,
  (
    product,
    roomInfo,
    hotelShopTrackingPropertiesV2
  ): ITrackingProperties | null => {
    return product && roomInfo
      ? {
          properties: {
            ...product?.trackingPropertiesV2?.properties,
            ...roomInfo?.trackingPropertiesV2?.properties,
            ...hotelShopTrackingPropertiesV2?.properties,
          },
          encryptedProperties: [
            product?.trackingPropertiesV2?.encryptedProperties ?? "",
            roomInfo?.trackingPropertiesV2?.encryptedProperties ?? "",
            hotelShopTrackingPropertiesV2?.encryptedProperties ?? "",
          ],
        }
      : {
          properties: {
            ...hotelShopTrackingPropertiesV2?.properties,
          },
          encryptedProperties: [
            hotelShopTrackingPropertiesV2?.encryptedProperties ?? "",
          ],
        };
  }
);

export const getHotelShopEntryPoint = (state: IStoreState) =>
  state.hotelShop.entryPoint;

export const getHotelShopRoomInfoProductsWithTransformedIndexes =
  createSelector(
    getHotelShopRoomInfoProducts,
    getHotelCfarQuoteByIndexSelector,
    roomInfoCfarQuotesSelector,
    getHotelCancellationPolicyByIndexSelector,
    getHotelAdditionalInfoByIndexSelector,
    getSearchedNightCount,
    hotelPriceFreezeOffersSelector,
    isRefundableRoomsWithPartiallyRefundablePolicyEnabledSelector,
    isHotelCfarRrMultipleCancellationPoliciesEnabledSelector,
    isHotelCfarModelV1EnabledSelector,
    shouldDisableRefundableRoomsSelector,
    useMatchCfarQuoteWithRoomIdSelector,
    getHotelShopSelectedLodging,
    (
      roomInfoProducts,
      getHotelCfarQuoteByIndex,
      cfarQuotes,
      getHotelCancellationPolicyByIndex,
      getHotelAdditionalInfoByIndex,
      nightCount,
      hotelPriceFreezeOffers,
      isRefundableRoomsWithPartiallyRefundablePolicyEnabled,
      isHotelCfarRrMultipleCancellationPoliciesEnabled,
      isHotelCfarModelV1Enabled,
      shouldDisableRefundableRooms,
      useMatchCfarQuoteWithRoomId,
      selectedLodging
    ): RoomInfoProductsWithTransformedIndexes[] => {
      return getShopRoomInfoProductsWithTransformedIndexes({
        roomInfoProducts,
        getHotelCfarQuoteByIndex,
        cfarQuotes,
        getHotelCancellationPolicyByIndex,
        getHotelAdditionalInfoByIndex,
        nightCount,
        pricesHelpers: {
          getModifiedLegacyPrices,
          getAddedLegacyPrices,
        },
        hotelPriceFreezeOffers,
        isRefundableRoomsWithPartiallyRefundablePolicyEnabled,
        isHotelCfarRrMultipleCancellationPoliciesEnabled,
        isHotelCfarModelV1Enabled,
        shouldDisableRefundableRooms,
        matchCfarQuotesWithRoomId: useMatchCfarQuoteWithRoomId,
        displayTaxesAndFeesIncluded: (selectedLodging as Lodging)?.price
          ?.displayTaxesAndFeesIncluded,
        displayTaxesAndFeesIncludedNightlyPrice: (selectedLodging as Lodging)
          ?.price?.displayTaxesAndFeesIncludedNightlyPrice,
      });
    }
  );

export const getHotelShopChosenPriceFreezeOffer = createSelector(
  [
    getHotelShopRoomInfoProductsWithTransformedIndexes,
    getHotelShopChosenRoomInfoIndex,
    getHotelShopChosenProductIndex,
  ],
  (
    roomInfoProductsWithTransformedIndexes,
    chosenRoomInfoIndex,
    chosenProductIndex
  ): LodgingPriceFreezeOffer | null => {
    if (chosenRoomInfoIndex !== null && chosenProductIndex !== null) {
      return (
        roomInfoProductsWithTransformedIndexes
          .flatMap((roomInfo) => roomInfo.products)
          .find(
            (product) =>
              product.roomProductVariant?.baseProduct.roomIndex ===
                chosenRoomInfoIndex &&
              product.roomProductVariant?.baseProduct.productIndex ===
                chosenProductIndex &&
              product.roomProductVariant?.variant === RoomProductType.Default
          )?.priceFreezeOffer?.offer ?? null
      );
    }

    return null;
  }
);

// Note that we can only have at most one Refundable Room product for a partciular Room
// due to the filtering logic performed in getHotelShopRoomInfoProductsWithTransformedIndexes
export const getHotelShopRoomInfoProductsRefundableRoomProductCount =
  createSelector(
    getHotelShopRoomInfoProductsWithTransformedIndexes,
    (roomInfoProductsWithTransformedIndexes) => {
      return roomInfoProductsWithTransformedIndexes.filter(
        (roomInfoProduct) =>
          roomInfoProduct.products.find(
            (product) =>
              product.roomProductVariant?.variant ===
              RoomProductType.RefundableRoom
          ) !== undefined
      ).length;
    }
  );

const getHotelShopRoomInfoRoomsImagesCount = createSelector(
  getHotelShopRoomInfoProducts,
  (
    roomInfoProducts
  ): {
    rooms_with_images_count: number;
    rooms_without_images_count: number;
  } => {
    let rooms_with_images_cnt = 0;
    let rooms_without_images_cnt = 0;
    for (var roomInfoProduct of roomInfoProducts) {
      rooms_with_images_cnt += roomInfoProduct.roomInfo.media.length ? 1 : 0;
      rooms_without_images_cnt += roomInfoProduct.roomInfo.media.length ? 0 : 1;
    }
    return {
      rooms_with_images_count: rooms_with_images_cnt,
      rooms_without_images_count: rooms_without_images_cnt,
    };
  }
);

export const getViewedHotelDetailsProperties = createSelector(
  getViewedHotelListProperties,
  getSelectedAccount,
  getHotelShopSelectedLodging,
  getViewedHotelDetailsTrackingProps,
  getHotelShopRoomInfoProducts,
  getHotelShopEntryPoint,
  getHotelShopRoomInfoProductsRefundableRoomProductCount,
  getHotelShopRoomInfoRoomsImagesCount,
  (
    viewedHotelListProperties,
    account,
    availability,
    trackingProps,
    roomInfoProducts,
    hotelShopEntryPoints,
    refundableRoomProductCount,
    roomInfoRoomsImagesCount
  ): ITrackingProperties<ViewedHotelDetailsProperties> => {
    const {
      lodgingSelection,
      selectedLodgingIndex,
    }: { lodgingSelection?: string; selectedLodgingIndex?: number } =
      queryStringParser.parse(location.search);

    let parsedLodgingSelection: Place | undefined;

    try {
      if (lodgingSelection) {
        parsedLodgingSelection = JSON.parse(
          decodeURIComponent(lodgingSelection)
        );
      }
    } catch (error) {
      console.error(error);
    }

    return {
      properties: {
        ...viewedHotelListProperties.properties,
        account_type_selected: account?.productDisplayName || "",
        rooms_shown: roomInfoProducts.length || 0,
        account_use_type: account?.accountUseType,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        entry_type: hotelShopEntryPoints ? hotelShopEntryPoints : undefined,
        room_media_count: availability?.lodging.media.length || 0,
        cfar_rr_offer: refundableRoomProductCount,
        rooms_with_images_count:
          roomInfoRoomsImagesCount.rooms_with_images_count,
        rooms_without_images_count:
          roomInfoRoomsImagesCount.rooms_without_images_count,
        google_place_id: parsedLodgingSelection?.placeId,
        lodging_row_index: selectedLodgingIndex
          ? Number(selectedLodgingIndex)
          : undefined,
        ...trackingProps?.properties,
        ...getLodgingTrackingProperties(availability).properties,
      },
      encryptedProperties: [
        ...viewedHotelListProperties.encryptedProperties,
        availability?.trackingPropertiesV2?.encryptedProperties ?? "",
        availability?.bestOfferThisLodging?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        ...(trackingProps?.encryptedProperties ?? []),
        ...getLodgingTrackingProperties(availability).encryptedProperties,
      ],
    };
  }
);

export const hotelShopProgressSelector = (state: IStoreState) =>
  state.hotelShop.progress;

export const getNearbyLodgings = (state: IStoreState) =>
  state.hotelShop.nearbyLodgings;

export const getNearbyLodgingsAvailabilityCallState = (state: IStoreState) =>
  state.hotelShop.nearbyLodgingsAvailabilityCallState;

export const getSimilarHotels = (state: IStoreState) =>
  state.hotelShop.similarHotels;

export const getSimilarHotelsAvailabilityCallState = (state: IStoreState) =>
  state.hotelShop.similarHotelsAvailabilityCallState;

export const getSimilarHotelsAvailabilityTrackingProperties = (
  state: IStoreState
) => state.hotelShop.similarHotelsAvailabilityTrackingProperties;

export const getDistanceFromLocation = (state: IStoreState) =>
  state.hotelShop.distanceFromLocation;

export const getDistanceFromLocationCategories = (state: IStoreState) =>
  state.hotelShop.distanceFromLocationCategories;

export const getDistanceFromLocationCategoriesLoading = (state: IStoreState) =>
  state.hotelShop.distanceFromLocationCategoriesLoading;

export const getSearchDistanceFromShopProperties = createSelector(
  getViewedHotelDetailsProperties,
  getDistanceFromLocation,
  (
    viewedHotelDetailsProperties,
    distanceFromLocation
  ): ITrackingProperties<SearchDistanceFromShopProperties> => {
    return {
      properties: {
        point_of_interest: distanceFromLocation?.label || "",
        ...viewedHotelDetailsProperties.properties,
      },
      encryptedProperties: [
        ...viewedHotelDetailsProperties.encryptedProperties,
      ],
    };
  }
);

export const getHotelShopRecommendedBasedOnPreferences = (state: IStoreState) =>
  state.hotelShop.recommendedBasedOnPreferences;

// Travel Wallet
export const showOfferBasedOnSelectedLodgingSelector = createSelector(
  getHotelShopSelectedLodging,
  getTravelWalletCredit,
  (selectedLodging, credit): boolean => {
    return (
      !!selectedLodging?.bestOfferThisLodging &&
      ((!!credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedLodging.bestOfferThisLodging.amount.amount)) ||
        !!selectedLodging.bestOfferThisLodging.availabilityPageBanner)
    );
  }
);

const areCfarIndexesInvalid = ({
  hotelShopChosenRoomInfoIndex,
  hotelShopChosenProductIndex,
  hotelShopSelectedLodgingId,
  lodgingIdWithCfarQuotes,
}: {
  hotelShopChosenRoomInfoIndex: number | null;
  hotelShopChosenProductIndex: number | null;
  hotelShopSelectedLodgingId: string | undefined;
  lodgingIdWithCfarQuotes: string | null;
}) => {
  return (
    hotelShopChosenRoomInfoIndex === null ||
    hotelShopChosenProductIndex === null ||
    !hotelShopSelectedLodgingId ||
    !lodgingIdWithCfarQuotes ||
    hotelShopSelectedLodgingId !== lodgingIdWithCfarQuotes
  );
};

// CFAR
export const hotelCfarQuoteFromChosenRoomProductSelector = createSelector(
  getHotelShopSelectedLodgingId,
  lodgingIdWithCfarQuotesSelector,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getSelectedRoomIdSelector,
  getHotelCfarQuoteByRoomIdSelector,
  getHotelShopChosenRateIdSelector,
  (
    hotelShopSelectedLodgingId,
    lodgingIdWithCfarQuotes,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    selectedRoomId,
    hotelCfarQuoteByRoomId,
    chosenHotelShopRateId
  ): HotelCfarQuote | null => {
    if (
      areCfarIndexesInvalid({
        hotelShopChosenRoomInfoIndex,
        hotelShopChosenProductIndex,
        hotelShopSelectedLodgingId,
        lodgingIdWithCfarQuotes,
      })
    ) {
      return null;
    }

    return hotelCfarQuoteByRoomId({
      selectedRoomId,
      selectedRateId: chosenHotelShopRateId,
    });
  }
);

export const cancellationPolicyFromChosenRoomProductSelector = createSelector(
  getHotelShopSelectedLodgingId,
  lodgingIdWithCfarQuotesSelector,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelCfarQuoteCancellationByRoomIdSelector,
  getSelectedRoomIdSelector,
  getHotelShopChosenRateIdSelector,
  (
    hotelShopSelectedLodgingId,
    lodgingIdWithCfarQuotes,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    getHotelCfarQuoteCancellationByRoomId,
    selectedRoomId,
    chosenRateId
  ): HotelCancellationPolicyV2 | null => {
    if (
      areCfarIndexesInvalid({
        hotelShopChosenRoomInfoIndex,
        hotelShopChosenProductIndex,
        hotelShopSelectedLodgingId,
        lodgingIdWithCfarQuotes,
      })
    ) {
      return null;
    }

    return getHotelCfarQuoteCancellationByRoomId({
      selectedRoomId,
      selectedRateId: chosenRateId,
    });
  }
);

export const additionalInfoFromChosenRoomProductSelector = createSelector(
  getHotelShopSelectedLodgingId,
  lodgingIdWithCfarQuotesSelector,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelCfarQuoteAdditionalInfoByRoomIdSelector,
  getSelectedRoomIdSelector,
  getHotelShopChosenRateIdSelector,
  (
    hotelShopSelectedLodgingId,
    lodgingIdWithCfarQuotes,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    getHotelCfarQuoteAdditionalInfoByRoomId,
    selectedRoomId,
    chosenHotelShopRateId
  ): HotelCfarQuoteAdditionalInfo | null => {
    if (
      areCfarIndexesInvalid({
        hotelShopChosenRoomInfoIndex,
        hotelShopChosenProductIndex,
        hotelShopSelectedLodgingId,
        lodgingIdWithCfarQuotes,
      })
    ) {
      return null;
    }

    return getHotelCfarQuoteAdditionalInfoByRoomId({
      selectedRoomId,
      selectedRateId: chosenHotelShopRateId,
    });
  }
);

export const getAddedAncillariesPricing = createSelector(
  selectedCfarIdSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  (
    selectedCfarId,
    hotelCfarQuoteFromChosenRoomProduct
  ): PassengerAncillaryPricing[] => {
    const ancillaryPricing: PassengerAncillaryPricing[] = [];
    if (
      !!selectedCfarId &&
      !!hotelCfarQuoteFromChosenRoomProduct &&
      selectedCfarId.value === hotelCfarQuoteFromChosenRoomProduct.id.value
    ) {
      ancillaryPricing.push({
        kind: AncillaryKindEnum.Cfar,
        premium: hotelCfarQuoteFromChosenRoomProduct.premiumPrices,
      });
    }
    return ancillaryPricing;
  }
);

export const isCfarAvailableInChosenRoomProductSelector = createSelector(
  isHotelCfarEnabledSelector,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  hotelCfarQuoteFromChosenRoomProductSelector,
  hasSelectedRefundableRoomSelector,
  (
    isHotelCfarEnabled,
    hotelShopChosenRoomInfoIndex,
    hotelShopChosenProductIndex,
    hotelCfarQuoteFromChosenRoomProduct,
    hasSelectedRefundableRoom
  ): boolean => {
    return (
      isHotelCfarEnabled &&
      hotelShopChosenRoomInfoIndex !== null &&
      hotelShopChosenProductIndex !== null &&
      hotelCfarQuoteFromChosenRoomProduct !== null &&
      !hasSelectedRefundableRoom
    );
  }
);

export const hasSelectedCfarOptionSelector = createSelector(
  selectedCfarIdSelector,
  isCfarAvailableInChosenRoomProductSelector,
  (selectedCfarId, isCfarAvailable): boolean => {
    return !isCfarAvailable || !!selectedCfarId;
  }
);

export const isCfarOptionSelectionCompleteSelector = createSelector(
  isCfarAvailableInChosenRoomProductSelector,
  hasSelectedCfarOptionSelector,
  isHotelCfarEnabledSelector,
  (isCfarAvailable, hasSelectedCfarOption, isCfarEnabled): boolean => {
    return !isCfarEnabled || !isCfarAvailable || hasSelectedCfarOption;
  }
);

export const isAddOnOptionAvailableSelector = createSelector(
  isCfarAvailableInChosenRoomProductSelector,
  (isCfarAvailable): boolean => {
    return isCfarAvailable;
  }
);

export const isOptionSelectionCompleteSelector = createSelector(
  isCfarOptionSelectionCompleteSelector,
  (isCfarOptionSelectionComplete): boolean => {
    return isCfarOptionSelectionComplete;
  }
);

export const getRefundableRoomProductWithSelectedCfarId = createSelector(
  getHotelShopRoomInfoProductsWithTransformedIndexes,
  selectedCfarIdSelector,
  (
    roomInfoProductsWithTransformedIndexes,
    selectedCfarId
  ): RoomProductWithTransformedIndexes | undefined => {
    if (selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY) {
      let refundableRoomProduct: RoomProductWithTransformedIndexes | undefined =
        undefined;

      roomInfoProductsWithTransformedIndexes.find(
        (roomInfoProducts) =>
          !!roomInfoProducts.products.find((roomProduct) => {
            if (
              !!roomProduct?.roomProductVariant &&
              roomProduct.roomProductVariant.variant ===
                RoomProductType.RefundableRoom &&
              roomProduct.roomProductVariant.quoteId.value ===
                selectedCfarId.value
            ) {
              refundableRoomProduct = roomProduct;
              return true;
            }
            return false;
          })
      );

      return refundableRoomProduct;
    }

    return undefined;
  }
);

// Note that we can only have at most one Refundable Room product for a partciular Room
// due to the filtering logic performed in getHotelShopRoomInfoProductsWithTransformedIndexes
export const getRefundableRoomProductCorrespondingToChosenRoom = createSelector(
  getHotelShopChosenRoomInfoIndex,
  getHotelShopRoomInfoProductsWithTransformedIndexes,
  (chosenRoomInfoIndex, roomInfoProductsWithTransformedIndexes) => {
    if (
      chosenRoomInfoIndex !== null &&
      roomInfoProductsWithTransformedIndexes[chosenRoomInfoIndex]
    ) {
      return roomInfoProductsWithTransformedIndexes[
        chosenRoomInfoIndex
      ].products.find(
        (product) =>
          product.roomProductVariant?.variant === RoomProductType.RefundableRoom
      );
    }

    return undefined;
  }
);

export const getHotelCfarOfferChoicePropertiesSelector = createSelector(
  selectedCfarIdSelector,
  getHotelShopChosenRoomInfoIndex,
  getHotelShopChosenProductIndex,
  getHotelShopRoomInfoProductsWithTransformedIndexes,
  hotelCfarQuoteFromChosenRoomProductSelector,
  hasSelectedRefundableRoomSelector,
  (
      selectedCfarId,
      hotelShopChosenRoomInfoIndex,
      hotelShopChosenProductIndex,
      hotelShopRoomInfoProductsWithTransformedIndexes,
      hotelCfarQuoteFromChosenRoomProduct,
      hasSelectedRefundableRoom
    ): ((
      context: "shop" | "customize" | "book"
    ) => Pick<
      Cap1HotelCfarOfferFactsProperties,
      "cfar_rr_hotel_choice" | "cfar_choice"
    >) =>
    (context: "shop" | "customize" | "book") => {
      // note: it cannot be replaced by hotelCfarQuoteFromChosenRoomProductSelector, because the refundable room can be filtered out
      const isRefundableRoomOffered: boolean =
        !!hotelShopRoomInfoProductsWithTransformedIndexes.find(
          (roomInfoProduct) =>
            !!roomInfoProduct.products.find((product) => {
              const roomProductVariant = product.roomProductVariant;
              if (
                !!roomProductVariant &&
                roomProductVariant.variant === RoomProductType.RefundableRoom
              ) {
                const { roomIndex, productIndex } =
                  roomProductVariant.baseProduct;
                return (
                  hotelShopChosenRoomInfoIndex === roomIndex &&
                  hotelShopChosenProductIndex === productIndex
                );
              }
              return false;
            })
        );
      const hasSelectedCfar =
        !!selectedCfarId && selectedCfarId.value !== DO_NOT_APPLY_OPTION_KEY;
      const refundableRoomChoice = isRefundableRoomOffered
        ? hasSelectedRefundableRoom
          ? 1
          : 0
        : "none";
      const cfarChoice = !!hotelCfarQuoteFromChosenRoomProduct
        ? hasSelectedCfar
          ? 1
          : 0
        : "none";

      switch (context) {
        case "shop": {
          /*
            cfar_choice:
              0 = hotel rate selected
              1 = c1 refundable room selected 
              none = no c1 refundable room offered

            cfar_rr_hotel_choice
              0 = hotel rate selected
              1 = c1 refundable room selected
              none = no c1 refundable room offered
          */
          return {
            cfar_choice: refundableRoomChoice,
            cfar_rr_hotel_choice: refundableRoomChoice,
          };
        }
        case "customize": {
          /*
            cfar_choice
              0 = hotel rate selected
              1 = c1 refundable room selected OR standalone cfar attached
              none = no c1 refundable room/standalone cfar offered
          */
          return {
            cfar_choice: cfarChoice,
          };
        }
        case "book": {
          /*
            cfar_choice
              0 = hotel rate selected
              1 = c1 refundable room selected OR standalone cfar attached
              none = no c1 refundable room/ no standalone cfar offered

            cfar_rr_hotel_choice
              0 = hotel rate selected
              1 = c1 refundable room 
              none = no c1 refundable room offered
          */
          return {
            cfar_choice: cfarChoice,
            cfar_rr_hotel_choice: refundableRoomChoice,
          };
        }
      }
    }
);

// Corporate Travel

export const getHotelShopChosenProductHotelLoyaltyProgramCode = createSelector(
  [getHotelShopChosenProduct],
  (product) => {
    return product && "loyaltyProgramCode" in product
      ? product.loyaltyProgramCode ?? ""
      : "";
  }
);

export const getUserPassengers = (state: IStoreState) =>
  state.hotelBook.userPassengers;

export const getUserSelectedTravelerId = (state: IStoreState) =>
  state.hotelBook.userSelectedTravelerId;

//TODO(remove): legacy loyalty number fetcher for pre multiroom amadeus epic
export const getLoyaltyNumberForChosenProduct = createSelector(
  [
    getHotelShopChosenProductHotelLoyaltyProgramCode,
    getUserPassengers,
    getUserSelectedTravelerId,
  ],
  (loyaltyProgram, passengers, selectedTravelerId) => {
    if (!loyaltyProgram || !selectedTravelerId) {
      return null;
    }

    const traveler = passengers?.find(
      (passenger) => passenger.id === selectedTravelerId
    );

    return traveler?.hotelLoyalty[loyaltyProgram]?.value ?? null;
  }
);
