import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
} from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelAvailabilityMapTooltip,
  Icon,
  IconName,
  OutOfPolicyModal,
  getTotalPriceText,
  hotelPriceFormatter,
  isSignificantSavings,
} from "halifax";
import React, { useContext, useEffect, useState } from "react";
import {
  CorpLodging,
  HOTEL_LIST_MAP_CAROUSEL,
  HotelStarRatingEnum,
  IIdLodgings,
  Lodging,
  LodgingCollectionEnum,
  LodgingId,
  LodgingSelectionEnum,
  ModalScreens,
  SELECTED_HOTEL_FROM_MAP,
  ViewedCorpRateDescriptorEntryPoints,
  getLodgingTrackingProperties,
} from "redmond";

import {
  LodgingPromotionType,
  StayType,
} from "redmond/hotels-module/interfaces";
import config from "../../../../../../../../../cap1-application/b2b-base/src/utils/config";
import { ClientContext } from "../../../../../../App";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  STAYS_SEARCH,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../../../context/experiments";
import {
  PATH_SHOP,
  PATH_VACATION_RENTAL_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../../../utils/paths";
import { transformToStringifiedQuery } from "../../../../../shop/utils/queryStringHelpers";
import { ISetLodgingIdInFocus } from "../../../../actions/actions";
import { ConnectedAvailabilityMapPricePinProps } from "./container";
import { onOpenCompareBarTooltip } from "../../../../../../utils/events";
import "./styles.scss";

export interface IAvailabilityMapPricePinProps
  extends ConnectedAvailabilityMapPricePinProps {
  lodging: Lodging | CorpLodging;
  isInFocus: boolean;
  isHovered: boolean;
  isPreviouslyShown: boolean;
  nightCount: number | null;
  isPreview?: boolean;
  isDesktop?: boolean;
  setLodgingIdInFocus: (
    lodgingId: LodgingId | null
  ) => ISetLodgingIdInFocus | void;
}

export const AvailabilityMapPricePin = (
  props: IAvailabilityMapPricePinProps
) => {
  const {
    lodging,
    isInFocus,
    isHovered,
    isPreviouslyShown,
    setLodgingIdInFocus,
    nightCount,
    hotelQueryParams,
    isPreview,
    isDesktop,
    accountReferenceId,
    viewedHotelListProperties,
    searchLocation,
    roomsCount,
    mapBounds,
    isVentureX,
  } = props;

  const [hovered, setHovered] = useState(isHovered);
  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);
  const [showPolicyModal, setShowPolicyModal] = useState<string>("");
  const expState = useExperiments();

  const isMultiroomAmadeus = useExperimentIsVariant(
    "corp-amadeus-multiroom",
    "available"
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const outOfPolicyCopy =
    isAutoApprovalEnabled || isApprovalsV2Enabled
      ? "If you wish to proceed with your selection, admins will be notified upon booking that this hotel was out of policy."
      : "This hotel rate is out of your company policy. You can continue anyway or change your selection.";

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  useEffect(() => {
    setHovered(isHovered);
  }, [isHovered]);

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = React.useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const isCorporate = "corporateTravel" in lodging.lodging;

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const isHome = lodging.stayType === StayType.Homes;

  const onCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLodgingIdInFocus(null);
  };

  const onClickHotelDetails = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLodgingIdInFocus(lodging.lodging.id);
    let params = transformToStringifiedQuery({
      lodgingId: lodging.lodging.id,
      lodgingSelection: mapBounds
        ? {
            LodgingSelection: LodgingSelectionEnum.Location,
            descriptor: mapBounds,
          }
        : (searchLocation?.id as IIdLodgings).lodgingSelection,
      ...hotelQueryParams,
      roomsCount,
      fromHotelAvailability:
        lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
        lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? true
          : undefined,
    });

    if (isHome && staysSearchEnabled) {
      // Vacation Rentals (Homes) uses listingId instead of lodgingId in the shop URL
      params = params.replace("lodgingId", "listingId");
    }

    trackEvent({
      eventName: SELECTED_HOTEL_FROM_MAP,
      properties: {
        ...lodging?.trackingPropertiesV2?.properties,
        ...viewedHotelListProperties.properties,
        is_preferred_cot: lodging.isPreferred,
        ...getLodgingTrackingProperties(lodging).properties,
      },
      encryptedProperties: [
        lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
        ...viewedHotelListProperties.encryptedProperties,
        ...getLodgingTrackingProperties(lodging).encryptedProperties,
      ],
    });

    const path =
      isHome && staysSearchEnabled
        ? PATH_VACATION_RENTAL_SHOP
        : lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
          lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
        ? PREMIER_COLLECTION_PATH_SHOP
        : PATH_SHOP;

    if (isCorporate && !isInPolicy) {
      trackEvent(
        getShowOOPModalEvent(
          ModalScreens.HOTELS_AVAILABILITY,
          "hotels",
          modalType
        )
      );
      return setShowPolicyModal(`${PATH_SHOP}${params}`);
    }
    window.open(`${path}${params}`, "_blank");
  };

  const onClickPricePin = (e: React.MouseEvent) => {
    e.stopPropagation();
    isInFocus
      ? setLodgingIdInFocus(null)
      : setLodgingIdInFocus(lodging.lodging.id);
  };

  const showOnSaleIcon =
    isLodgingPromotionsExperiment &&
    lodging.bestPromotionThisLodging?.promotionType ===
      LodgingPromotionType.DealOfTheDay &&
    lodging.lodging.tripAdvisorReviews &&
    lodging.lodging.tripAdvisorReviews.overallScore * 5 >= 3.5 &&
    lodging.price?.discountHunting &&
    lodging.price?.discountHunting?.savingsAmount.fiat.value >= 5 &&
    (isVentureX
      ? lodging.lodging.starRating === HotelStarRatingEnum.Four ||
        lodging.lodging.starRating === HotelStarRatingEnum.Five
      : true);

  const showPCStyling =
    isPremierCollectionEnabled &&
    lodging.lodgingCollection === LodgingCollectionEnum.Premier;
  const showLCStyling =
    (isLCForPremiumCardHoldersEnabled || isLCForNonPremiumCardHoldersEnabled) &&
    lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle;

  const isInPolicy =
    isCorporate &&
    (lodging as CorpLodging).lodging.corporateTravel.policyCompliance
      .isInPolicy;

  const hasLoyalty = isCorporate && lodging.loyaltyProgramCode;

  const tenant = config.TENANT;

  if (!lodging.price) {
    return null;
  }

  const price =
    lodging.price.displayTaxesAndFeesIncludedNightlyPrice &&
    (lodging.price.nightlyDiscountAwareTaxesAndFeesIncluded
      ?.priceWithUnmanagedDiscounts.fiat ||
      lodging.price.nightlyPriceTaxesAndFeesIncluded?.fiat)
      ? lodging.price.nightlyDiscountAwareTaxesAndFeesIncluded
          ?.priceWithUnmanagedDiscounts.fiat ||
        lodging.price.nightlyPriceTaxesAndFeesIncluded?.fiat
      : lodging.price.nightlyDiscountAware?.priceWithUnmanagedDiscounts.fiat ||
        lodging.price.nightlyPrice.fiat;

  if (!price) {
    return null;
  }

  const multiroomPrice = {
    ...price,
    value: price.value / roomsCount,
  };

  return (
    <>
      <Box
        className={clsx(
          "lodging-marker-wrapper",
          { inFocus: isInFocus },
          { hovered: isInFocus || hovered },
          { previouslyShown: isPreviouslyShown },
          {
            "premier-collection": showPCStyling,
            "lifestyle-collection": showLCStyling,
          }
        )}
      >
        <OutOfPolicyModal
          subtitle={outOfPolicyCopy}
          isOpen={Boolean(showPolicyModal)}
          isMobile={!isDesktop}
          onClose={() => {
            setShowPolicyModal("");
            trackEvent(
              getClickCancelOOPModalEvent(
                ModalScreens.HOTELS_AVAILABILITY,
                "hotels",
                modalType
              )
            );
          }}
          onContinue={() => {
            window.open(showPolicyModal, "_blank");
            setShowPolicyModal("");
            trackEvent(
              getClickContinueOOPModalEvent(
                ModalScreens.HOTELS_AVAILABILITY,
                "hotels",
                modalType
              )
            );
          }}
          isApprovalRequired={
            policies?.settings && policies.settings.isApprovalRequired
          }
        />
        {isInFocus && isDesktop && (
          <Box
            className={clsx("lodging-details-container", {
              "in-policy": isInPolicy,
            })}
          >
            {showPCStyling && <Icon name={IconName.PremierCollectionRibbon} />}
            {showLCStyling && (
              <Icon name={IconName.LifestyleCollectionRibbon} />
            )}
            <HotelAvailabilityMapTooltip
              lodging={lodging}
              onCloseClick={(ev) => onCloseClick(ev)}
              onClickHotelDetails={(ev) => onClickHotelDetails(ev)}
              nightCount={nightCount ? nightCount : 0}
              rewardsKey={accountReferenceId || undefined}
              isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
              isVentureX={isVentureX}
              onViewCarouselImage={() =>
                trackEvent({
                  eventName: HOTEL_LIST_MAP_CAROUSEL,
                  properties: {},
                })
              }
              policyLimit={policies?.hotels.policies[0].maxPricePerNight}
              tenant={config.TENANT}
              searchedRoomsCount={isCorporate ? roomsCount : undefined}
              onViewCorpCompareBar={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_MAP
              )}
            />
          </Box>
        )}
        <Button
          className={clsx("price-pin", tenant, {
            "exclusive-rate": isSignificantSavings(
              lodging.price,
              lodging.bestPromotionThisLodging
            ),
            "pc-price-pin-wrapper": showPCStyling || showLCStyling,
            "out-of-policy": isCorporate && !isInPolicy,
          })}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={(e) => (!isPreview ? onClickPricePin(e) : null)}
        >
          {showOnSaleIcon ? (
            <Icon
              className="preferred-icon"
              name={IconName.PreferredHotelIcon}
            />
          ) : null}
          {(showPCStyling || showLCStyling) && (
            <Icon name={IconName.PremierCollectionPricePin} />
          )}
          {isInPolicy && (
            <Icon className="policy-icon" name={IconName.CheckMarkGreen} />
          )}
          {hasLoyalty && (
            <Icon className="loyalty-icon" name={IconName.StarCircledIcon} />
          )}
          <Box className="price-pin-label">
            {getTotalPriceText({
              price: isMultiroomAmadeus ? multiroomPrice : price,
              priceFormatter: hotelPriceFormatter,
            })}
          </Box>
        </Button>
      </Box>
    </>
  );
};
