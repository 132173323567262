import {
  MobileHotelShopRoomTypePickerPanel,
  TrackingEventControlType,
} from "halifax";
import React, { useEffect, useMemo, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { SELECT_HOTEL_ROOM } from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { MobileRoomPickerConnectorProps } from "./container";

export interface IMobileRoomPickerProps
  extends MobileRoomPickerConnectorProps,
    RouteComponentProps {
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
}

export const MobileRoomPicker = (props: IMobileRoomPickerProps) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchHotelShop,
    handleReadyToRedirect,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);

  const expState = useExperiments();

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_RECENTLY_VIEWED_HOTELS,
    MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchHotelShop(history, {
          forceCallHotelAvailability: true,
          fetchSimilarHotels: similarHotelsVariant !== CONTROL,
          includeLocationSearchTerm:
            isRecentlyViewedV1Experiment &&
            recentlyViewedMobileP0Variant !== CONTROL &&
            shouldTrackLodgingLocation(history.location.search),
        });
      }
    }
  }, [checkinDate, checkoutDate]);

  return (
    <MobileHotelShopRoomTypePickerPanel
      {...props}
      onClickContinue={() => {
        const room = props.chosenRoomInfo && props.chosenRoomInfo.roomInfo.name;
        trackEvent({
          eventName: SELECT_HOTEL_ROOM,
          properties: { room_type: room },
        });
        if (handleReadyToRedirect) {
          handleReadyToRedirect();
        }
      }}
      className="b2b"
    ></MobileHotelShopRoomTypePickerPanel>
  );
};
